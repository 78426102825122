<template>
  <div :class="contentClass">
      <button @click.stop.prevent="doIt()" @click="handleClick" :aria-label="btn.ariaLabel" class="btn" :class="[customClass, 'btn-' + btn.type, { 'btn-no-label': btn.label == false }, { 'btn-loading': isLoading }]">
          <span> {{ btn.label ? btn.label : '' }}</span>
      </button>
  </div>
</template>

<script>
export default {
  props: {
      contentClass: { type: String, default: 'content-button' },
      bType: { type: String, default: 'normal' },
      bLabel: { type: [String, Boolean], default: '' },
      bCallback: { type: Function, default: function () {} },
      bCallbackProps: {
          type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
          default: undefined
      },
      customClass: { type: [String, Array, Object, Function], default: '' },
      isLoading: { type: Boolean, default: false },
      bTypeOfBtn: { type: String, default: 'button' }
  },
  data() {
      return {
          types: {
              cancel: { label: 'button.cancel', callback: function () {} }
          }
      }
  },
  computed: {
      btn() {
          var def = this.types[this.bType]
          var result = {
              type: this.bType ? this.bType : 'normal',
              label: this.bLabel === false ? false : this.bLabel != '' ? this.$t(this.bLabel) : this.$t(def.label),
              callback: this.bCallback ? this.bCallback : def.callback,
              props: this.bCallbackProps ? this.bCallbackProps : undefined,
              ariaLabel: def && def.ariaLabel ? this.$t(def.ariaLabel) : ''
          }
          return result
      }
  },
  methods: {
      doIt() {
          var self = this
          if (this.bType == 'save' && this.$store.getters['multilanguage/getTranslationLang']) {
              this.$nextTick(() => {
                  self.$store.dispatch('multilanguage/saveTranslations')
              })
          }

          if (!this.isLoading) {
              this.btn.callback(this.btn.props)
          }
      },
      handleClick() {
          this.$emit('on-button-click')
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.content-title-des {
  width: 110%;
  height: 20px !important;
  margin-left: 0rem;
  .btn {
      @include background($color: $color-neutral-200, $size: 15px, $position: 11px center);
      @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
      padding: 8px 9px 8px 35px;
      margin: 0 0 0 0;
      font-size: 14px;
      height: 20px !important;
      width: fit-content;
      font-weight: 700;
      color: $color-white;
      border-radius: 4px;
      line-height: initial;

      span {
          font-weight: 700;
          color: $color-black;
          border-radius: 4px;
          line-height: initial;
      }
  }

  .btn-deselect-all {
      margin: 2px auto !important;
      color: $color-white;
      background-color: $color-white !important;
      padding-left: 5px !important;
      &::before {
          content: '';
          @include background($image: img('select_all_black.svg'), $size: 15px, $position: left center);
          width: 15px;
          height: 15px;
          position: absolute;
      }
      span {
          padding-left: 25px;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
  }
}
.content-title {
  width: 100%;
  height: 20px !important;
  margin-left: 0.7rem;
  .btn {
      @include background($color: $color-neutral-200, $size: 15px, $position: 11px center);
      @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
      padding: 8px 9px 8px 35px;
      margin: 0 0 0 0;
      font-size: 14px;
      height: 20px !important;
      width: fit-content;
      font-weight: 700;
      color: $color-white;
      border-radius: 4px;
      line-height: initial;

      span {
          font-weight: 700;
          color: $color-black;
          border-radius: 4px;
          line-height: initial;
      }
  }

  .btn-select-all {
      margin: 2px auto !important;
      color: $color-white;
      background-color: $color-white !important;
      padding-left: 10px !important;
      &::before {
          content: '';
          @include background($image: img('select_all_black.svg'), $size: 15px, $position: left center);
          width: 15px;
          height: 15px;
          position: absolute;
      }
      span {
          padding-left: 25px;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
  }
  .btn-delete-all {
      margin: 2px auto !important;
      color: $color-white;
      background-color: $color-error-500 !important;
      padding-left: 10px !important;
      &::before {
          content: '';
          @include background($image: img('delete_ffffff.svg'), $size: 15px, $position: left center);
          width: 15px;
          height: 15px;
          position: absolute;
      }
      span {
          padding-left: 25px;
          color: $color-white;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
  }
}
.content-button {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  .btn {
      @include font-size(sm);
      @include background($color: $color-neutral-200, $size: 15px, $position: 11px center);
      @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
      padding: 8px 9px 8px 35px;
      margin: 0 0 0 12px;
      height: auto;
      width: fit-content;
      font-family: $text-bold;
      color: $color-white;
      border-radius: 4px;
      line-height: initial;

      span {
          font-family: $text-bold;
          color: $color-white;
          border-radius: 4px;
          line-height: initial;
      }
  }
  // Common styles

  .btn-cancel {
      color: $color-neutral-600;
      background-color: $color-neutral-300h;
      border: 1px solid $color-neutral-300h;
      margin: 16px auto !important;

      &:hover {
          background-color: $color-neutral-300h;
          border: 1px solid $color-neutral-300h;
      }

      &.disabled {
          background-color: $color-neutral-300h;
          color: $color-neutral-300h;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 16px 0 0;
          color: $color-neutral-600 !important;
          background-color: $color-neutral-300h;
          border: 1px solid $color-neutral-300h;
      }

      span {
          color: $color-neutral-600 !important;
          font-weight: none;
      }
  }

  .btn-print {
      margin: 8px auto !important;
      color: $color-black;
      background-color: $color-neutral-300 !important;
      padding-left: 30px !important;
      height: 48px !important;
      &::before {
          content: '';
          @include background($image: img('printer_producttimer.svg'), $size: 20px, $position: left center);
          width: 20px;
          height: 20px;
          position: absolute;
      }
      span {
          padding-left: 30px;
          color: $color-black;
      }
      &:hover {
          background-color: $color-neutral-300;
          border: 1px solid $color-neutral-500;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-black;
          background-color: $main;
      }
  }

  .btn-move {
      margin: 8px auto !important;
      color: $color-white;
      background-color: $main-text !important;
      height: 48px !important;
      &::before {
          content: '';
          @include background($image: img('swap_producttimer.svg'), $size: 20px, $position: left center);
          width: 20px;
          height: 20px;
          position: absolute;
      }
      span {
          padding-left: 30px;
      }
      &:hover {
          background-color: $color-neutral-300;
          border: 1px solid $color-neutral-500;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
      &.blocked {
          background-color: $color-neutral-500 !important;
      }
  }

  .btn-delete {
      margin: 16px auto !important;
      color: $color-white;
      background-color: $color-error-500 !important;
      &::before {
          content: '';
          @include background($image: img('delete_ffffff.svg'), $size: 20px, $position: left center);
          width: 20px;
          height: 20px;
          position: absolute;
      }
      span {
          padding-left: 30px;
      }
      &:hover {
          background-color: $color-neutral-300;
          border: 1px solid $color-neutral-500;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
  }

  .btn-delete-timer {
      margin: 8px auto !important;
      color: $color-white;
      background-color: $color-error-500 !important;
      height: 48px !important;
      &::before {
          content: '';
          @include background($image: img('delete_ffffff.svg'), $size: 20px, $position: left center);
          width: 20px;
          height: 20px;
          position: absolute;
      }
      span {
          padding-left: 30px;
      }
      &:hover {
          background-color: $color-neutral-300;
          border: 1px solid $color-neutral-500;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
  }

  .btn-change-view {
      margin: 8px 0px -10px 0px !important;
      color: $color-white;
      background-color: $color-secondary-500 !important;
      span {
          padding-left: 0px;
      }
      &:hover {
          background-color: $color-neutral-300;
          border: 1px solid $color-neutral-500;
      }

      &.disabled {
          background-color: $color-neutral-300;
          color: $color-neutral-300;
          cursor: not-allowed;
      }

      &.selector-popup {
          width: 100%;
          padding: 8px 9px;
          margin: 8px 0;
          color: $color-white;
          background-color: $main;
      }
      &.blocked {
          background-color: $color-neutral-500 !important;
      }
  }
}
</style>